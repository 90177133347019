<template>
  <div class="Footer">
    <div class="foo-cont">
      <div class="menu-i">
        <a href="/">HOME</a>
        <a href="/conocenos">CONÓCENOS</a>
        <a href="/blog">BLOG</a>
        <a href="/colaboradores">COLABORADORES</a>
      </div>
      <a href="" class="logo">
        <img src="../../assets/foodie-l.png" alt="logo" />
      </a>
      <div class="polices-c">
        <div class="col-1">
          <a href="/politicas-de-privacidad">Política de privacidad</a>
          <a href="">Newsletter</a>
        </div>
        <p>Copyright© {{ year }} Derechos Reservados</p>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      year: null,
    }
  },

  mounted() {
    this.year = dayjs().format('YYYY');
  },
};
</script>
<style>
.foo-cont {
  width: 89.855vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12.077vw 0;
  border-top: 0.725vw solid #a6a6a6;
}

.menu-i {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.menu-i a,
.polices-c a,
.polices-c p {
  font-size: 4vw !important;
  padding: 0px 2.415vw;
  line-height: 7vw;
  font-family: var(--RedHat);
  color: black;
  font-weight: 600;
}

.foo-cont .logo {
  margin: 10.87vw 0;
}

.foo-cont .logo img {
  width: 72.464vw;
  height: auto;
}

.foo-cont .polices-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {

  .menu-i a,
  .polices-c a,
  .polices-c p {
    font-size: 0.797vw !important;
    padding: 0px 1.302vw;
    line-height: 0.797vw;
  }

  .foo-cont {
    width: 89.855vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.604vw 0;
    border-top: 0.26vw solid #a6a6a6;
  }

  .foo-cont .logo {
    margin: 2.344vw 0;
  }

  .foo-cont .logo img {
    width: 15.625vw;
    height: auto;
  }
}
</style>